<template>
	<div class="system-content">
		<div class="system-left" :style="height">
			<p class="title">培训管理</p>
			<div class="menu-box">
				<el-menu
					:default-active="defaultActivePath"
					router>
					<menu-item v-for="(item,index) in menuList" :item="item" :key="index"></menu-item>
				</el-menu>
			</div>
		</div>
		<div class="system-right p1">
			<transition name="fade-transform" mode="out-in">
				<router-view :key="key"/>
			</transition>
		</div>
	</div>
</template>
<script>
import MenuItem from '@/components/MenuItem'
export default {
	components: {
		MenuItem
	},
	data() {
		return {
			perms: JSON.parse(window.sessionStorage.getItem('perms')),
			height: '',
			menuList: [],
			defaultActivePath: ''
		}
	},
	computed: {
        key() {
            return this.$route.path
		}
	},
	created() {
		this.init();
		// 处理路由重复点击报错问题
		this.handleRepeatRoute();
	},
	watch: {
		$route(from,to) {
			this.init();
			this.handleRepeatRoute();
		}
	},
	methods: {
		init() {
			this.height = 'min-height:' + (window.innerHeight - 180) + 'px;';
			let menuArray = [];
			if(this.perms.includes('train:schedule')) {
				menuArray.push(
					{
						title: '培训日程表',
						icon: 'ricoh-icon-1',
						name: 'schedule',
						path: '/trainingManagement/schedule'
					}
				);
			}
			if(this.perms.includes('train:online')) {
				let routeObj = {
						title: '远程培训',
						icon: 'ricoh-icon-2',
						path: 'online',
						children: []
					};
				if(this.perms.includes('train:online:publish')) {
					routeObj.children.push(
						{
							title: '发布培训',
							path: '/trainingManagement/onlinePublish',
						}
					);
				}
				if(this.perms.includes('train:online:exam')) {
					routeObj.children.push(
						{
							title: '远程考试',
							path: '/trainingManagement/onlineExam',
						}
					);
				}
				if(this.perms.includes('train:online:resultManage')) {
					routeObj.children.push(
						{
							title: '考试成绩',
							path: '/trainingManagement/onlineResult',
						}
					);
				}
				menuArray.push(routeObj);
			}
			if(this.perms.includes('train:offline')) {
				let routeObj = {
						title: '集合培训',
						icon: 'ricoh-icon-3',
						path: 'offline',
						children: []
					};
				if(this.perms.includes('train:offline:list')) {
					routeObj.children.push(
						{
							title: '发布培训',
							path: '/trainingManagement/offlineAdd',
						}
					);
				}
				if(this.perms.includes('train:offline:register')) {
					routeObj.children.push(
						{
							title: '集合报名',
							path: '/trainingManagement/offlineSign',
						}
					);
				}
				if(this.perms.includes('train:offline:resultManage')) {
					routeObj.children.push(
						{
							title: '考试成绩',
							path: '/trainingManagement/offlineResult',
						}
					);
				}
				if(this.perms.includes('train:offline:addResult')) {
					routeObj.children.push(
						{
							title: '补登成绩',
							path: '/trainingManagement/offlineAppend',
						}
					);
				}
				menuArray.push(routeObj);
			}
			if(this.perms.includes('train:question')) {
				let routeObj = {
						title: '课程管理',
						icon: 'ricoh-icon-4',
						path: 'course',
						children: []
					};
				if(this.perms.includes('train:question:no')) {
					routeObj.children.push(
						{
							title: '课程编号管理',
							path: '/trainingManagement/courseManagement',
						}
					);
				}
				if(this.perms.includes('train:question:maintain')) {
					routeObj.children.push(
						{
							title: '试题维护管理',
							path: '/trainingManagement/questionManagement',
						}
					);
				}
				menuArray.push(routeObj);
			}
			if(this.perms.includes('train:company')) {
				menuArray.push(
					{
						title: '公司管理',
						icon: 'ricoh-icon-5',
						path: '/trainingManagement/companyManagement'
					}
				);

			}
			if(this.perms.includes('train:student')) {
				menuArray.push(
					{
						title: '学员管理',
						icon: 'ricoh-icon-6',
						path: '/trainingManagement/studentManagement'
					}
				);
			}
			if(this.perms.includes('train:teacher')) {
				menuArray.push(
					{
						title: '教师管理',
						icon: 'ricoh-icon-7',
						path: '/trainingManagement/teacherManagement'
					}
				);
			}
			if(this.perms.includes('train:room')) {
				menuArray.push(
					{
						title: '会场管理',
						icon: 'ricoh-icon-8',
						path: '/trainingManagement/roomManagement'
					}
				);
			}
			if(this.perms.includes('train:history')) {
				let routeObj = {
						title: '培训履历',
						icon: 'ricoh-icon-9',
						path: 'history',
						children: []
					};
				if(this.perms.includes('train:history:online')) {
					routeObj.children.push(
						{
							title: '远程',
							path: '/trainingManagement/onlineHistory',
						}
					);
				}
				if(this.perms.includes('train:history:offline')) {
					routeObj.children.push(
						{
							title: '集合',
							path: '/trainingManagement/offlineHistory',
						}
					);
				}
				if(this.perms.includes('train:history:onlineInvalid')) {
					routeObj.children.push(
						{
							title: '远程-失效',
							path: '/trainingManagement/onlineInvalidHistory',
						}
					);
				}
				if(this.perms.includes('train:history:offlineInvalid')) {
					routeObj.children.push(
						{
							title: '集合-失效',
							path: '/trainingManagement/offlineInvalidHistory',
						}
					);
				}
				menuArray.push(routeObj);
			}
			this.menuList = menuArray;
			// 刷新页面不自动跳转回左侧第一个菜单
			if(/train/.test(this.$route.path) && this.$route.name != 'trainingManagement') {
				return
			}
			// 进入页面自动加载左侧第一个菜单
			if(menuArray.length > 0) {
				if(menuArray[0].children) {
					this.$router.push({
						path: menuArray[0].children[0].path
					})
				}else {
					this.$router.push({
						path: menuArray[0].path
					})
				}
			}
		},
		handleMenu(item) {

		},
		// 处理路由重复点击报错问题
		handleRepeatRoute() {
			if(this.$route.name == 'questionList') {
				this.defaultActivePath = '/trainingManagement/questionManagement';
			}
			else if(this.$route.name == 'signMember') {
				this.defaultActivePath = '/trainingManagement/offlineSign';
			}else if(this.$route.name == 'offlineResultDetail' && this.$route.query.page == 'result') {
				this.defaultActivePath = '/trainingManagement/offlineResult';
			}else if(this.$route.name == 'offlineResultDetail' && this.$route.query.page == 'append') {
				this.defaultActivePath = '/trainingManagement/offlineAppend';
			}else {
				this.defaultActivePath = this.$route.path;
			}
		}
	}
}
</script>
<style>
	.p1{
		padding: 15px;
	}
	.p2{
		padding: 0;
	}
</style>
<style lang="scss" scoped>
.system-content{
	display: flex;
	box-sizing: border-box;
	padding-top: 20px;
	.system-left{
		width: 190px;
		background-color: #fff;
		border-radius: 10px;
		box-sizing: border-box;
		padding-top: 25px;
		min-height: 520px;
		// max-height: 730px;
		.menu-box{
			box-sizing: border-box;
			padding-right: 15px;
			background-color: #fff;
			border-radius: 10px;
			// overflow: auto;
			// max-height: 730px;
			// &::-webkit-scrollbar{
			// 	width:5px;
			// 	height:10px;
			// }
			// &::-webkit-scrollbar-track{
			// 	// background: rgb(239, 239, 239);
			// 	border-radius:2px;
			// }
			// &::-webkit-scrollbar-thumb{
			// 	background: #ddd;
			// 	border-radius:5px;
			// }
			// &::-webkit-scrollbar-thumb:hover{
			// 	background: #333;
			// }
		}
		.title{
			height: 30px;
			font-size: 24px;
			margin-bottom: 10px;
			text-align: center;
		}
	}
	.system-right{
		flex: 1;
		background-color: #fff;
		border-radius: 10px;
		margin-left: 10px;
		box-sizing: border-box;
	}

	.el-menu{
		border-right: none;
		border-radius: 10px;
		.el-menu-item,
		/deep/ .el-submenu__title{
			border-top-right-radius: 26px;
			border-bottom-right-radius: 26px;
		}
		.el-menu-item.is-active{
			background-color: #ecf5ff;
		}
	}
}
</style>
