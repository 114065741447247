<template>
	<div>
		<el-submenu v-if="item.children" :index="item.path">
			<template slot="title">
				<i :class="item.icon"></i>
				<span slot="title">{{item.title}}</span>
			</template>
			<menu-item v-for="(child,i) in item.children" :item="child" :key="i"></menu-item>
		</el-submenu>
		<el-menu-item v-else :index="item.path">
			<i :class="item.icon" :style="item.icon ? '' : 'width:10px;'"></i>
			<span slot="title">{{item.title}}</span>
		</el-menu-item>
	</div>
</template>
<script>
export default {
	name: 'MenuItem',
	props: {
		item: {
			type: Object,
			required: true
		},
	},
	data() {
		return {

		}
	}
}
</script>
<style lang="scss" scoped>
.el-menu{
	border-right: none;
	border-radius: 10px;
	.el-menu-item,
	/deep/ .el-submenu__title{
		border-top-right-radius: 26px;
		border-bottom-right-radius: 26px;
	}
	.el-menu-item.is-active{
		background-color: #ecf5ff;
	}
}
.el-submenu {
	.el-menu-item{
		min-width: 151px;
	}
}
.el-menu-item,
.el-submenu{
	padding-left: 0;
	i{
		width: 20px;
		height: 20px;
		display: inline-block;
		margin-right: 10px;
	}
	.ricoh-icon-1{
		width: 22px;
		height: 22px;
		background: url('~@/assets/images/menu-icon/1.png') no-repeat;
		background-size: 19px 19px;
	}
	.ricoh-icon-2{
		width: 22px;
		height: 22px;
		background: url('~@/assets/images/menu-icon/2.png') no-repeat;
		background-size: 19px 19px;
	}
	.ricoh-icon-3{
		background: url('~@/assets/images/menu-icon/3.png') no-repeat;
		background-size: 17px 17px;
	}
	.ricoh-icon-4{
		background: url('~@/assets/images/menu-icon/4.png') no-repeat;
		background-size: 17px 17px;
	}
	.ricoh-icon-5{
		background: url('~@/assets/images/menu-icon/5.png') no-repeat;
		background-size: 17px 17px;
	}
	.ricoh-icon-6{
		background: url('~@/assets/images/menu-icon/6.png') no-repeat;
		background-size: 17px 17px;
	}
	.ricoh-icon-7{
		width: 22px;
		height: 20px;
		background: url('~@/assets/images/menu-icon/7.png') no-repeat;
		background-size: 19px 17px;
	}
	.ricoh-icon-8{
		background: url('~@/assets/images/menu-icon/8.png') no-repeat;
		background-size: 17px 17px;
	}
	.ricoh-icon-9{
		background: url('~@/assets/images/menu-icon/9.png') no-repeat;
		background-size: 17px 17px;
	}
}
.el-menu-item.is-active{
	.ricoh-icon-1{
		width: 22px;
		height: 22px;
		background: url('~@/assets/images/menu-icon/a1.png') no-repeat;
		background-size: 19px 19px;
	}
	.ricoh-icon-2{
		width: 22px;
		height: 22px;
		background: url('~@/assets/images/menu-icon/a2.png') no-repeat;
		background-size: 19px 19px;
	}
	.ricoh-icon-3{
		background: url('~@/assets/images/menu-icon/a3.png') no-repeat;
		background-size: 17px 17px;
	}
	.ricoh-icon-4{
		background: url('~@/assets/images/menu-icon/a4.png') no-repeat;
		background-size: 17px 17px;
	}
	.ricoh-icon-5{
		background: url('~@/assets/images/menu-icon/a5.png') no-repeat;
		background-size: 17px 17px;
	}
	.ricoh-icon-6{
		background: url('~@/assets/images/menu-icon/a6.png') no-repeat;
		background-size: 17px 17px;
	}
	.ricoh-icon-7{
		width: 22px;
		height: 20px;
		background: url('~@/assets/images/menu-icon/a7.png') no-repeat;
		background-size: 19px 17px;
	}
	.ricoh-icon-8{
		background: url('~@/assets/images/menu-icon/a8.png') no-repeat;
		background-size: 17px 17px;
	}
	.ricoh-icon-9{
		background: url('~@/assets/images/menu-icon/a9.png') no-repeat;
		background-size: 17px 17px;
	}
}
</style>
